"use client";

import { Loader, MantineProvider, Modal, Popover } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { SessionProvider } from "next-auth/react";

import { QueryClient, QueryClientProvider, isServer } from "@tanstack/react-query";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import { TooltipProvider } from "@/components/ui/tooltip";

type Props = {
  children?: React.ReactNode;
};

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export const queryClient = getQueryClient();

export const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <SessionProvider>
        <MantineProvider
          defaultColorScheme="dark"
          forceColorScheme="dark"
          theme={{
            autoContrast: true,
            components: {
              Loader: Loader.extend({
                defaultProps: {
                  size: "xl",
                  type: "dots",
                },
              }),
              Modal: Modal.extend({
                defaultProps: {
                  closeButtonProps: {
                    color: "red",
                    size: 20,
                    className: "bg-red-500 text-red-900 text-[14px] rounded-full",
                  },
                },
                classNames: {
                  content: "bg-[#0B0B0C] border-zinc-800 border-[1px] border-solid",
                  title: "font-bold",
                  header: "bg-[#0B0B0C]",
                  body: "bg-[#0B0B0C]",
                },
              }),
            },
          }}
        >
          <Notifications position="top-right" zIndex={10000} />

          <TooltipProvider>{children}</TooltipProvider>
        </MantineProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
};
